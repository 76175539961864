@import "https://unpkg.com/normalize.css" layer(normalize);
@font-face {
  font-family: "geist Sans";
  src: url("https://assets.codepen.io/605876/GeistVF.ttf") format("truetype");
}
@layer foundation {
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
  body {
		min-height: 100vh;
		background: hsl(0 0% 100%);
		font-family:  "Geist Sans", "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif, system-ui;
		font-weight: 80;
	}
	
	header {
		min-height: 100vh;
	}
  video {
		position: sticky;
		top: 0;
		height: 100vh;
		width: 100vw;
		object-fit: cover;
	}

	header > section {
		position: relative;
		top: 0;
		height: 100vh;
		width: 100%;
		translate: 0 -100%;
		display: grid;
		place-items: center;
		color: hsl(0 0% 100%);
	}

	h1 {
		--font-size: clamp(2rem, 4vw + 1rem, 8rem);
		font-size: var(--font-size);
		text-align: center;
		display: grid;
		gap: 0.5rem 0;
		line-height: 0.9;
		text-wrap: balance;
		font-weight: 120;
	}

	h1 span:first-of-type {
		font-size: calc(var(--font-size) * 0.35);
	}

	header > section::before {
		content: "";
		position: absolute;
		inset: -100% 0 -100% 0;
		background: hsl(0 0% 0% / 0.5);
		pointer-events: none;
		z-index: -1;
	}

	main {
		min-height: 100vh;
		display: grid;
		place-items: center;
	}

	main > section {
		font-size: clamp(2rem, 3vw + 1rem, 8rem);
		font-weight: 100;
	}

	footer {
		padding: 2rem;
		display: grid;
		place-items: center;
	}
}
@layer scrolls {
  @supports (animation-timeline: scroll()){
    header {
      transform-origin: 50% 0%;
      animation: scale-down both ease-in;
      animation-timeline: view();
      animation-range: exit;
      view-timeline: --header;
    }
    @keyframes scale-down {
      to {
        scale: 0.8 0.8;
      } 
    }
    header > section::before{
      animation: fade both linear;
      animation-timeline: --header;
      animation-range: exit-crossing 0% exit 0%;
    }
    @keyframes fade {
      to {
        opacity: 0;
      }
    }
  }
}
@layer socials {
  .x-link,
  .spoti-link {
    color: hsl(0 0% 100%);
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: 48px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    opacity: 0.8;
    mix-blend-mode: difference;
  }

  .spoti-link {
    bottom: unset;
    top: 1rem;
  }

  :where(.x-link, .spoti-link):is(:hover, :focus-visible) {
    opacity: 1;
  }
  .spoti-link svg {
    width: 75%;
  }
  .x-link svg {
    width: 50%;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    
  }
}
.text-container {
  animation: fadeInUp 3s ease-out forwards;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 100;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 96px;
  color: white;
  opacity: 0.8;
  user-select: none;
  text-shadow: 1px 1px rgba(0,0,0,0.1);
}
.sub-text {
  opacity: 0%;
  animation: fadeInUp 2s ease-out forwards 3.5s;
  transform: translateY(60px);
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  user-select: none;
  text-shadow: 1px 1px rgba(0,0,0,0.1);
}
.text-container , .sub-text {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.hidden {
  opacity: 0; // Faded out
  transform: translateY(10px); // Moves down slightly
}

.new-component {
  opacity: 0; // Initially hidden
  transition: opacity 0.5s ease-in-out;
}

.show {
  opacity: 1; // Faded in
}

// // Hiding the scrollbar
// body {
//   overflow-y: scroll; // Keeps the layout stable
//   scrollbar-width: none; // For Firefox

//   &::-webkit-scrollbar {
//     display: none; // For Webkit browsers
//   }
// }
:root {
  --color-bg1: rgb(82, 0, 122);
  --color-bg2: rgb(0, 17, 82);
  --color1: 18, 113, 255;
  --color2: 221, 74, 255;
  --color3: 100, 220, 255;
  --color4: 200, 50, 50;
  --color5: 50, 180, 20;
  --color-interactive: 140, 100, 255;
  --circle-size: 80%;
  --blending: hard-light;
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}


.gradient-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  svg {
    display: none;
  }


  .gradients-container {
    filter: url(#goo) blur(40px) ;
    width: 100%;
    height: 100%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: center center;
    animation: moveVertical 30s ease infinite;

    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;

    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;

    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s ease infinite;

    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}