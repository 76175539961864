.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000;
}
@keyframes tick {
    75% {
        transform: rotate(32deg)
    }
    
    100% {
        transform: rotate(30deg);
    }
  }
  
  .rotating-image-container {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px; /* Set this to half of the new width you want for your image */
    height: 400px; /* Set this to half of the new height you want for your image */
    overflow: hidden;
    z-index: 10; /* Ensures the image is above other content */
  }
  
  .rotating-image-container img {
    animation: tick 1s steps(1) infinite;
    animation-timing-function: cubic-bezier(.4,2.08,.55,.44);
    width: 200%; /* Double the percentage to make the image larger */
    position: absolute;
    bottom: 0; /* Aligns the image to show the bottom part */
    left: 0%; /* Shifts the image to the left to display the bottom left quarter */
   
  }
  

.clock_content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.clock_content h1 {
    color: var(--white-color);
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.clock_content h3 {
    color: var(--primary-color);
    font-weight: 300;
    margin-bottom: 3rem;
}

.flip-clock {
    --fcc-flip-duration: 0.5s;
    /* transition duration when flip card */
    --fcc-digit-block-width: 30px;
    /* width of digit card */
    --fcc-digit-block-height: 60px;
    /* height of digit card, highly recommend in even number */
    --fcc-digit-font-size: 30px;
    /* font size of digit */
    --fcc-digit-color: #000;
    /* color of digit */
    --fcc-label-font-size: 10px;
    /* font size of label */
    --fcc-label-color: var(--white-color);
    /* color of label */
    --fcc-background: var(--white-color);
    /* background of digit card */
    --fcc-divider-color: #000;
    /* color of divider */
    --fcc-divider-height: 1px;
    /* height of divider */
    --fcc-separator-size: 6px;
    /* size of colon */
    --fcc-separator-color: var(--white-color);
    /* color of colon */
    font-weight: 700;
    margin-bottom: 2rem;
}

.button {
    width: 140px;
    height: 45px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
    background-color: var(--white-color);
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.button:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    transform: translateY(-7px);
    box-shadow: 0 15px 20px rgba(46, 104, 229, 0.4);
}

@media (min-width: 900px) {
    .clock_content h1 {
        font-size: 3rem;
    }

    .clock_content h3 {
        font-size: 1.5rem;
    }

    .flip-clock {
        --fcc-digit-block-width: 80px;
        --fcc-digit-block-height: 120px;
        --fcc-digit-font-size: 60px;
        --fcc-label-font-size: 20px;
    }
}